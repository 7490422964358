package com.pkgdotzip.kotwebwebsite.pages

import androidx.compose.runtime.Composable
import com.pkgdotzip.kotwebwebsite.components.sections.AllReposSection
import com.pkgdotzip.kotwebwebsite.components.sections.FooterSection
import com.pkgdotzip.kotwebwebsite.components.sections.HeaderSection
import com.pkgdotzip.kotwebwebsite.components.widgets.ThemeChangeButton
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page

@Page
@Composable
fun Repositories() {
    ThemeChangeButton()

    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        // Header.
        HeaderSection()

        // Site contents.
        AllReposSection()

        // Footer.
        FooterSection()
    }
}