package com.pkgdotzip.kotwebwebsite

object Consts {

    // Social Media.
    const val LINKEDIN_URL = "https://www.linkedin.com/in/${Config.Socials.LINKEDIN_USERNAME}/"
    const val GITHUB_URL = "https://github.com/${Config.Socials.GITHUB_USERNAME}/"
    const val TWITTER_URL = "https://twitter.com/${Config.Socials.TWITTER_USERNAME}"

    // Mail.
    const val MAIL_TO = "mailto:${Config.Socials.MAIL}"

    object SoftwareUrl {
        const val GIT = "https://git-scm.com/"
        const val GITKRAKEN_CLIENT = "https://www.gitkraken.com/git-client"
        const val FL_STUDIO = "https://www.image-line.com/fl-studio/"
        const val PREMIERE_PRO = "https://www.adobe.com/products/premiere.html"
        const val PHOTOSHOP = "https://www.adobe.com/products/photoshop.html"
        const val OBSIDIAN_MD = "https://obsidian.md/"
        const val CHAT_GPT = "https://chat.openai.com/"

        object IDE {
            const val INTELLIJ = "https://www.jetbrains.com/idea/"
            const val PYCHARM = "https://www.jetbrains.com/pycharm/"
            const val CLION = "https://www.jetbrains.com/clion/"
            const val ANDROID = "https://developer.android.com/studio"
            const val F4 = "https://github.com/xored/f4"
            const val GOLAND = "https://www.jetbrains.com/go/"
            const val RESHARPER = "https://www.jetbrains.com/resharper/"
            const val VSCODE = "https://code.visualstudio.com/"
        }
    }

    object ProgrammingLangUrl {
        const val C = "https://en.wikipedia.org/wiki/C_(programming_language)"
        const val CPP = "https://en.wikipedia.org/wiki/C%2B%2B"
        const val GO = "https://go.dev/"
        const val CSHARP = "https://dotnet.microsoft.com/en-us/languages/csharp"
        const val JAVA = "https://dev.java/"
        const val KOTLIN = "https://kotlinlang.org/"
        const val FANTOM = "https://fantom.org/"
        const val MARKDOWN = "https://www.markdownguide.org/"
        const val PYTHON = "https://www.python.org/"
    }
}