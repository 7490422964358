package com.pkgdotzip.kotwebwebsite

import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.rgb

// Contain all graphics (e.g. font + color) & files related constants.
object Res {
    object Image {
        private const val IMAGE_PATH = "image/"
        const val PERSONAL_PICTURE = "${IMAGE_PATH}/fakePerson.jpg"

        // Logo.
        object Logo {
            private const val LOGO_PATH = "${IMAGE_PATH}logo/"
            const val LOGO_DARK = "${LOGO_PATH}/logo_ai_upscaled.png"
            const val LOGO_LIGHT = "${LOGO_PATH}/logo_ai_upscaled_inverted.png"
        }

        // Software.
        object Software {
            private const val SOFTWARE = "${IMAGE_PATH}software/"
            const val ANDROID_LOGO = "${SOFTWARE}Android.svg"
            const val GIT_LOGO = "${SOFTWARE}Git.svg"
            const val INTELLIJ_LOGO = "${SOFTWARE}IntelliJ.svg"
            const val CLION_LOGO = "${SOFTWARE}CLion.svg"
            const val GOLAND_LOGO = "${SOFTWARE}GoLand.svg"
            const val PYCHARM_LOGO = "${SOFTWARE}PyCharm.svg"
            const val RESHARPER_LOGO = "${SOFTWARE}ReSharper.svg"
            const val FL_STUDIO_LOGO = "${SOFTWARE}FLStudio.svg"
            const val VSCODE_LOGO = "${SOFTWARE}VSCode.svg"
            const val GITKRAKEN_LOGO = "${SOFTWARE}GitKraken.svg"
            const val F4_IDE_LOGO = "${SOFTWARE}F4.svg"
            const val PHOTOSHOP_LOGO = "${SOFTWARE}Photoshop.svg"
            const val PREMIERE_LOGO = "${SOFTWARE}PremierePro.svg"
            const val OBSIDIAN_MD_LOGO = "${SOFTWARE}ObsidianMD.svg"
            const val CHAT_GPT_LOGO = "${SOFTWARE}ChatGPT.svg"
        }

        // Skills.
        object Skill {
            private const val SKILL = "${IMAGE_PATH}skills/"
            const val CSS_LOGO = "${SKILL}CSS.svg"
            const val FANTOM_LOGO = "${SKILL}Fantom.svg"
            const val GOLANG_LOGO = "${SKILL}GoLang.svg"
            const val CSHARP_LOGO = "${SKILL}CSharp.svg"
            const val C_LOGO = "${SKILL}C.svg"
            const val CPP_LOGO = "${SKILL}Cpp.svg"
            const val PYTHON_LOGO = "${SKILL}Python.svg"
            const val HTML_LOGO = "${SKILL}HTML.svg"
            const val JAVA_LOGO = "${SKILL}Java.svg"
            const val JAVASCRIPT_LOGO = "${SKILL}Javascript.svg"
            const val KOTLIN_LOGO = "${SKILL}Kotlin.svg"
            const val MARKDOWN_LOGO = "${SKILL}Markdown.svg"
        }

        // Socials.
        object Socials {
            private const val SOCIALS = "${IMAGE_PATH}socials/"
            const val GITHUB = "${SOCIALS}github.svg"
            const val LINKEDIN = "${SOCIALS}linkedin.svg"
            const val TWITTER_X = "${SOCIALS}twitter-x.svg"
        }
    }

    // Fonts.
    object Font {
        const val ARIAL = "Arial"
        const val UBUNTU = "Ubuntu"
        const val ROBOTO = "Roboto"
        const val HELVETICA = "Helvetica"
        const val SANS_SERIF = "sans-serif"
        const val LUCIDA_CONSOLE = "Lucida Console"
        const val COURIER_NEW = "Courier New"
        const val MONOSPACE = "monospace"

        // These are added in the build.gradle.kts file.
        const val INTER = "Inter"
        const val BITTER = "Bitter"
        const val RALEWAY = "Raleway"
        const val ROKKITT = "Rokkitt"
        const val JOST = "Jost"
        const val MAJOR_MONO_DISPLAY = "Major Mono Display"
    }

    // Colors (noted in RGB, can also take hex).
    object Color {
        val SOFTWARE_AND_SKILLS_BOX_BORDER_COLOR_LIGHT = rgb(233, 233, 233)
        val SOFTWARE_AND_SKILLS_BORDER_COLOR_DARK = rgb(78, 78, 78)
        val FOOTER_COLOR_LIGHT = rgb(241, 241, 241)
        val FOOTER_COLOR_DARK = rgb(13, 13, 13)
    }
}