package com.pkgdotzip.kotwebwebsite.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun MarkdownPage() {
    com.varabyte.kobweb.compose.foundation.layout.Column {
        org.jetbrains.compose.web.dom.H1(attrs = { id("kobweb-tutorial") }) {
            org.jetbrains.compose.web.dom.Text("Kobweb Tutorial")
        }
    }
}
